import React from "react";
import { styled,Box} from "@material-ui/core";
import { TextFieldCustom } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { searchImg } from "./assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";
interface Props {
  searchSpecificSec:string,
  handleSectionSearchBar:(text:string)=>void,
  specificSecSearchesData:any,
  onLinkClick:(link:number)=>void
}
interface S {}
interface SS {}

const SearchInput = styled(TextFieldCustom)({
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 70%)",
  padding: 1,
  width: '100%',
  paddingTop:"2px",
  '@media (max-width: 900px)': {
  width: '100%',
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    background: 'white'
  },

  "& .MuiOutlinedInput-input": {
    padding: "16px 4px !important",

  },

  "& .MuiOutlinedInput-adornedStart":{
    paddingLeft: '8px',
  },

  "& .MuiOutlinedInput-input::placeholder": {
    fontSize:'18px',
  }
});



export default class SectionSearchBar extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }
  render(): React.ReactNode {
    const {searchSpecificSec,handleSectionSearchBar} = this.props;
    return (
      <Box>
        <SearchInput
        // placeholder="Jump to hereee"
        placeholder="Jump to specific section"
        variant="outlined"
        value={searchSpecificSec}
        data-test-id = "searchSpecSection"
        onChange={(e:any)=>handleSectionSearchBar(e)}
        InputProps={{
          startAdornment: (
            <img
              width={"17px"}
              height={"17px"}
              src={searchImg}
              alt="search icon"
            />
          ),
        }}
        className="custom-textfield"
      />
             <style>
        {`

          .custom-textfield .MuiOutlinedInput-notchedOutline {
            border-width: 0px !important; 
          }

          .custom-textfield .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important; 
          }

          .custom-textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important; 
          }

          .custom-textfield .MuiOutlinedInput-input:focus {
            outline: none !important; 
          }
        `}
      </style>
      </Box>
    );
  }
}
