import { IPostData } from "../../blocks/customform/src/CustomFormController.web"


export const pathName= (style1:any, style2:any)=>{
    const path=window.location.pathname.includes('survey')
    return path ? style1 : style2
  }


  export const debounce=(callBack:(args:IPostData)=>void,delay:number)=>{
      let timeout:NodeJS.Timeout|null = null
      return (args:IPostData)=>{
        timeout&&clearTimeout(timeout)
        timeout = setTimeout(()=>callBack(args),delay)
      }
    }
  