import { BlockComponent } from "../../../framework/src/BlockComponent";
import { CustomFormLink } from "./CustomFormController.web";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import storage from "../../../framework/src/StorageProvider.web";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";


export interface Props {
  navigation: any;
  onLinkClick: (id: number) => void;
  handleScrollToHeading: (name: string) => void;
  surveyName?: string;
  links: CustomFormLink[];
  onNextBtnClick: () => void;
  onBackBtnClick: () => void;
  hasNextSection?: boolean;
  onSubmit: (status:string) => void;
  showPopUp: boolean;
  error: string;
  onPopUpClose: () => void;
  onSaveBtnClick: (status:string) => void;
  saveBtnResponse:boolean;
  searchSpecificSec:string;
  specificSecSearchesData:any,
  handleSectionSearchBar:(text:string)=>void,
  sectionSubSectionList:any,
  handleGlobalSearch: (option: string) => void;
  globalSearchOptions: string[];
  navigationSearchSections:any;
  listOfSectionsSubsections:any;
  linksSectionsSubsections:any;
  handleError: (error: string) => void;
  name:string;
  openAccordions:any[];
  deleteSubSecPopup:boolean;
  handleCloseDeleteSubsecPopup:()=>void;
  subSecName:string;
  handleDeleteElementAccordion:()=>void;
  addSimilarResponseData:any ;
  fetchSurveyDetails:any
}

interface S {
  hasOpenSidebar: boolean;
  hasOpenGloableSearchBar: boolean;
  openPopup:boolean;
  surveyName: string;
  userSurveyId:number;
  propertyOwner: string;
  surveyNameError: boolean;
  propertyOwnerError: boolean;
  validationsError: string;
  externalLink:boolean
}

export const configJSON = require("./config");

export interface ApidataResponseMock {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface InvalidResponseType {
  errors: string;
}

interface SS {}

export class CustomFormWrapperController extends BlockComponent<Props, S, SS> {
  isDesktop: boolean = window.innerWidth > 900;
  postTemplateSurveyApiCallId: string = "";
  getSurveyDetailsCallId: string = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      hasOpenSidebar: this.isDesktop,
      hasOpenGloableSearchBar: false,
      openPopup:false,
      surveyName: "",
      userSurveyId: 0,
      propertyOwner: "",
      surveyNameError: false,
      propertyOwnerError: false,
      validationsError: "",
      externalLink: false
    };
  }

  componentDidMount = async() => {
    if(global.innerWidth > 435){
      this.setState({hasOpenGloableSearchBar: true })
    }
    let createUserResponse=await getStorageData("createUserResponse")
    if(createUserResponse){
      createUserResponse=JSON.parse(createUserResponse)
      this.setState({
        userSurveyId:createUserResponse.data.id
      })
    }
    const urlParams = new URLSearchParams(window.location.search);
    const externalLink = urlParams.get('is_open');
    this.setState({externalLink : externalLink ?true:false});
  }
  setOpenSidebar = (open: boolean) => {
    this.setState({ hasOpenSidebar: open });
  };
  toggleSearchbar = ()=>{
    this.setState((pre)=>({
      hasOpenGloableSearchBar: !pre.hasOpenGloableSearchBar
    }))
  }
  customPopUp = ()=>{
    this.setState({
      openPopup:true
    })
  }
  handleClosePopup = () => {
    this.setState({
      openPopup: !this.state.openPopup,
      surveyName: "",
      propertyOwner: "",
      surveyNameError: false,
      propertyOwnerError: false,
      validationsError: ""
    });
  };

  createSurveyName = async (eventSubmitTemplate: React.FormEvent<HTMLFormElement>) => {
    eventSubmitTemplate.preventDefault();
    
    const { userSurveyId,surveyName, propertyOwner } = this.state;
  
    this.setState({
      validationsError: "",
      surveyNameError: false,
      propertyOwnerError: false
    });

    if (surveyName === "" && propertyOwner === "") {
      this.setState({
        validationsError: 'Please enter <span>Survey Name</span> & <span>Property Owner</span>',
        surveyNameError: true,
        propertyOwnerError: true
      });
    } else if (surveyName === "") {
      this.setState({
        validationsError: "Please enter <span>Survey Name</span>",
        surveyNameError: true,
        propertyOwnerError: false
      });
    } else if (propertyOwner === "") {
      this.setState({
        validationsError: "Please enter <span>Property Owner Name</span>",
        surveyNameError: false,
        propertyOwnerError: true
      });
    } else {
      this.setState({validationsError: ""})
      const URL = window.location.href;
    const segments = URL.split('/');
    const shareId = segments[segments.length - 2];
      let apiBody = {
        user_survey_id: shareId,
        survey_name: surveyName,
        owner_name: propertyOwner,
      };
      this.postTemplateSurveyApiCallId = await this.apiCallData({
        contentType: configJSON.validationApiContentType,
        method: configJSON.editUserApiMethod,
        endPoint: `${configJSON.createSurveyApiEndPoint}/${shareId}`,
        body: apiBody
      });
      this.handleClosePopup()
      setTimeout(() => {
        this.props.fetchSurveyDetails()
      }, 1000);
    }
  };

  apiCallData = async (data: ApidataResponseMock) => {
    const URL = window.location.href;
    const segments = URL.split('/');
    const shareId = segments[segments.length - 2];
    const token = await storage.get("auth-token");
    const { userSurveyId, surveyName, propertyOwner } = this.state;
    const header = {
      "Content-Type": "application/json",
      token: token,
    }

    let body = {
      survey_name: surveyName,
      owner_name: propertyOwner,
      user_survey_id: shareId
      
    }

    
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createSurveyApiEndPoint}/${shareId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  setSurveyName = (surveyName: string) => {
    this.setState({
      surveyName: surveyName,
      surveyNameError: false
    });
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  setPropertyOwner = (ownerName: string) => {
    this.setState({
      propertyOwner: ownerName,
      propertyOwnerError: false
    });
  };
}
