import React, { ReactNode } from "react";
// Customizable Area Start
import LandingPageController, { Props, configJSON } from "./LandingPageController";
import ContentWrapper from "../../user-profile-advanced/src/ContentWrapper.web";
import { Box, Button, TextField, Typography, styled, Modal, Backdrop, Fade, Tooltip, withStyles, CircularProgress } from "@material-ui/core";
import { createSurveyImg, popupCrossIcon, logoutModalIcon, infoIcon, copyIcon, right, confirmationIcon, downloadSurveyIcon } from "./assets";
import EmptyHistory from "./EmptyHistory.web";
import SelectTemplatePopUp from "./SelectTemplatePopUp";
import SuccessDeletePopup from "./SuccessDeletePopup.web";
import SaveAsPdf from "../../saveaspdf/src/SaveAsPdf.web";

const LandingPageWrapper = styled(Box)({
  borderRadius: "20px",
  border: "1px solid transparent",
  padding: "44px 0 10px 0",
  backgroundColor: "white",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",

  position: "relative",
  "@media(max-width:500px)":{
    marginTop: "-60px",
    paddingBottom: "0"
  },
  "@media(max-width:435px)":{
    marginTop: "-50px"
  }
});

const CreateButton = styled(Button)({
  width: "258px",
  height: "258px",

  padding: "37px 40px 30px 40px",

  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",

  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "23px",
  fontWeight: 400,
  lineHeight: "37px",
  textTransform: "capitalize",

  "& .MuiButton-label": {
    aspectRatio: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },

  "&:hover": {
    backgroundColor: "unset"
  },

  "@media (max-width: 500px)": {
    display: "none"
  },
  
});

const CreateButtonMobile = styled(Button)({
  display: "none",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
  fontFamily: "Poppins, Inter, sans-serif",
  fontWeight: 400,
  lineHeight: "37px",
  textTransform: "capitalize",
  "& .MuiButton-label": {
    aspectRatio: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },

  "&:hover": {
    backgroundColor: "unset"
  },
  "@media (max-width: 500px)": {
    display: "block",
    width: "168px",
  height: "172.558px",
  padding: "22px 20px 20px 27px",
  fontSize: "16.27px",
  marginBottom: "10px"
  }
})

const BorderWrapper = styled("div")({
  marginTop: "36px",
  padding: "1px",
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 70%)",
  borderRadius: "16px",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  marginBottom: "17px",
  "@media (max-width: 500px)": {
    marginBottom: "0"
  },
  "@media (max-width: 900px)": {
    background: "none",
    boxShadow: "none"
  }
});

const CreateSurveyImg = styled("img")({
  width: "95px",
  height: "95px",
  "@media(max-width: 500px)":{
    width: "74px",
    height: "74px"
  }
});

const CustomPopup = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 2,
  borderRadius: "8px",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  flexDirection: "column",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  padding: "0px 0px 20px 0px",
  display: "flex",
  width: "50%",
  "@media (max-width: 500px)": {
    position: "fixed",
    zIndex: 1102,
    width: "calc(100% - 48px) !important"
  },
  "& .MuiDialog-paperFullWidth": {
    backgroundColor: "#FFF",
    padding: "0px 47px 0px 21px",
    borderRadius: "8px",
    width: "100%",
    boxSizing: "border-box",
    "@media (max-width: 600px)": {
      height: "unset",
      maxHeight: "unset",
      padding: "20px"
    },
    "@media (max-width: 450px)": {
      padding: "10px"
    }
  },
  "@media (max-width: 992px)": {
    width: "70%"
  },
  "@media (max-width: 435px)": {
    width: "80%"
  },
});

const ErrorPopupBox = styled(Box)({
  display: "flex",
  gap: "68px",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "40px",
  "@media (max-width: 500px)": {
    width: "calc(100% - 60px)",
    margin: "auto",
    marginTop: "24px"
  },
});

const ErrorLoginAlertPopup = styled(Box)({
  minWidth: "87%",
  borderTop: "4px solid #DC2626",
  padding: "12px 10px",
  borderRadius: "4px",
  backgroundColor: "#FEE2E2",
  "@media (max-width: 1024px)": {
    minWidth: "80%"
  },
  "@media (max-width: 550px)": {
    minWidth: "0%",
    width: "100%"
  },
  "@media (max-width: 375px)": {
    minWidth: "50%",
    padding: "12px 30px 12px 16px",
  }
});

const ErrorLoginAlertPopupMobile = styled(Box)({
  minWidth: "87%",
  borderTop: "4px solid #DC2626",
  borderRadius: "4px",
  padding: "12px 10px",
  backgroundColor: "#FEE2E2",
  "@media (max-width: 1024px)": {
    minWidth: "80%"
  },
  "@media (max-width: 550px)": {
    minWidth: "0%",
    width: "100%",
    marginBottom: "10px"
  }
});

const ErrorLoginInput = styled(Typography)({
  color: "#DC2626",
  fontFamily: "Poppins",
  fontSize: "12px !important",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "center",
  "@media(max-width:500px)":{
    textAlign: "left",
    "& span":{
      fontWeight: 700
    }
  }
});

const ErrorCopyInput = styled(Typography)({
  color: "#34D399",
  fontFamily: "Poppins",
  fontSize: "16px !important",
  fontStyle: "normal",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  gap: "12px",
  "@media(max-width:550px)": {
    fontSize: "12px !important"
  },
  "@media(max-width:375px)": {
    fontSize: "10px !important"
  }
});

const ErrorLoginInputMobile = styled(Typography)({
  color: "#DC2626",
  fontSize: "12px !important",
  fontFamily: "Poppins",
  fontWeight: 400,
  fontStyle: "normal",
  lineHeight: "18px",
  textAlign: "center"
});

const CloseDivIcon = styled(Box)({
  justifyContent: "flex-end",
  display: "flex",
  margin: "20px 20px 0px 0px",
  "@media(max-width:500px)":{
    margin: "0"
  }
});

const CloseIcon = styled("img")({
  cursor: "pointer",
  position: "absolute",
  top: "35px",
  "@media(max-width:500px)":{
    top: "28px",
    right: "24px"
  }
});

const MiddleContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const MiddleContainerMobile = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
});

const MainContiner = styled(Box)({
  boxSizing: "border-box"
});

const BorderTop = styled(Box)({
  marginTop:"40px",
 border: "1px solid #E2E8F0",
 "@media (max-width: 992px)": {
  display: "none"
 }
});

const Buttondiv = styled(Box)({
  padding: "30px 20px 0px 0px",
  textAlign: "end",
  "@media (max-width: 1250px)": {
    marginTop: "30px"
  },
  "@media (max-width: 992px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    textAlign: "center",
    padding: "10px 20px 0px 20px"
  },
  "@media(max-width:500px)":{
    padding: "10px 30px 0px 30px"
  }
});

const CancelLogoutButton = styled(Button)({
  backgroundColor: "rgb(237,245,253)",
  fontFamily: "Poppins",
  color: "#0090CF",
  fontWeight: 500,
  borderRadius: "8px",
  textTransform: "capitalize",
  height: "44px",
  fontSize: "16px",
  width: "235px",
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  "&:hover": {
    backgroundColor: "rgb(237,245,253)",
    color: "#0090CF"
  },
  "@media(max-width: 992px)": {
    fontSize: "14px"
  },
  "@media(max-width: 492px)": {
    fontSize: "12px"
  },
  "@media(max-width: 400px)": {
    height: "28px"
  }
});
const CancelButton = styled(Button)({
  width: "120px",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  marginRight: "10px",
  background: "var(--scio-services-light-blue-50-light-blue, #C2E1FA)",
  textTransform: "none",
  color: "#0090CF",
  "&:hover":{
    background: "var(--scio-services-light-blue-50-light-blue, #C2E1FA)",
    color: "#0090CF"
  },
  "@media (max-width: 992px)": {
    width:"100%",
    marginRight: "0px"
  }
});

const ConfirmButton = styled(Button)({
  width: "120px",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "var(--Scio-Services-Gradient, linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%))",
  textTransform: "none",
  color: "#FFFFFF",
  "@media (max-width: 992px)": {
    width:"100%"
  }
});

const Inputdiv = styled(Box)({
  marginTop: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px 30px 0px 30px",
  gap: "8px",
  "@media (max-width: 900px)": {
    marginTop: "20px"
  },
  "@media (max-width: 500px)": {
    marginTop: "24px",
    gap: "4px"
  }
});

const Heading = styled(Typography)({
  color: "var(--scio-services-basic-black, #181818)",
  fontFamily: "Poppins",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  padding: "5px 30px 10px 30px",
  "@media (max-width: 500px)": {
    padding: "0 0 0 30px",
    margin: "22px 0"
  },
  "@media (max-width: 375px)": {
    fontSize: "20px"
  }
});

const HrBorder = styled("hr")({
  border: "1px solid #E2E8F0",
  "@media(max-width: 500px)":{
    margin: "0"
  }
});

const LabelText = styled(Typography)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  marginRight: "0px",
  alignItems: "flex-start",
  marginBottom: "0px",
});

const Textfield = styled(TextField)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 8px",
    height: "30px"
  },
  background: "var(--scio-services-neutral-50-neutral, #F9FAFA)",
  "@media(max-width: 550px)":{
    "& .MuiOutlinedInput-input": {
      height: "17px"
    }
  },
  "@media(max-width: 500px)":{
    "& .MuiOutlinedInput-input": {
      height: "56px",
      boxSizing: "border-box"
    }
  }
});

const InputField = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#728088",
  fontStyle: "normal",
  fontWeight: 400,
  height: "30px",
  fontFamily: "Poppins",
  border: "1px solid #DCE1E4",
  fontSize: "16px",
  borderRadius: "4px",
  padding: "10px 0px",
  backgroundColor: "#F9FAFA",
  "@media(max-width: 550px)": {
      height: "17px"
  }
});

const IconImages = styled('img')({
  cursor: "pointer",
  marginRight: "15px"
});

const LinkTextField = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: "#728088",
  fontStyle: "normal",
  fontWeight: 400,
  fontFamily: "Poppins",
  fontSize: "16px",
  margin: "0px 20px 0px 20px",
  "@media(max-width: 550px)": {
    color: "#000000",
    fontSize: "14px",
    margin: "0px 10px 0px 10px"
  }
});

const TextfieldMobile = styled(TextField)({
  color: "#424C53",
  fontWeight: 500,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "16px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    height: "30px",
    padding: "10px 8px"
  },
  background: "var(--scio-services-neutral-50-neutral, #F9FAFA)",
  "@media(max-width: 550px)":{
    "& .MuiOutlinedInput-input": {
      height: "17px"
    }
  }
});

export const ListItem = styled("li")({
  display: "flex",
  alignItems: "center",
  marginTop: "8px",
  gap: "8px",
  color: " var(--scio-services-neutral-300-neutral, #A6B1B7)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal"
});

export const PopupBlurBackground = styled(Box)({
  background: "rgba(24, 24, 24, 0.40)",
  "-webkit-backdrop-filter": "blur(5px)",
  position: "absolute",
  borderRadius: "16px",
  border: "1px solid transparent",
  zIndex: 1,
  height: "100%",
  left: -1,
  width: "100%",
  top: -1,
  right:-1,
  "@media (max-width: 500px)": {
    position: "fixed",
    zIndex: 1101
  },
});

const LogoutPopup = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px"
});

const LogoutModalParaContainer = styled(Fade)({
  backgroundColor: "white",
  width: "50%",
  height: "250px",
  borderRadius: "8px",
  lineHeight: "2rem",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(max-width: 600px)": {
    gap: "15px"
  }
});

const LogoutParaOne = styled(Typography)({
  fontSize: "30px",
  textAlign: "center",
  fontWeight: 600,
  fontFamily: "Poppins",
  "@media(max-width: 992px)": {
    fontSize: "22px"
  },
  "@media(max-width: 467px)": {
    fontSize: "16px"
  },
  "@media(max-width: 360px)": {
    fontSize: "15px"
  }
});

const LogoutButtonContainer = styled(Box)({
  display: "flex",
  gap: "18px",
  justifyContent: "center",
  width: "85%",
  "@media(max-width:467px)": {
    flexDirection: "column",
    alignItems: "center"
  }
});

const LogoutButton = styled(Button)({
  background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
  color: "#FFF",
  borderRadius: "8px",
  height: "44px",
  width: "235px",
  fontSize: "16px",
  fontWeight: 500,
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  fontFamily: "Poppins",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    color: "#FFF"
  },
  "@media(max-width: 900px)": {
    fontSize: "14px"
  },
  "@media(max-width: 492px)": {
    fontSize: "12px"
  },
  "@media(max-width: 400px)": {
    height: "28px"
  }
});

const LinkInputdiv = styled(Box)({
  marginTop: "45px",
  display: "flex",
  alignItems: "center",
  padding: "0px 30px 0px 30px",
  gap: "15px",
  "@media (max-width: 900px)": {
    marginTop: "45px",
    flexDirection: "column"
  },
  "@media (max-width: 350px)": {
    padding: "0px 10px 0px 10px"
  }
});

const LinkInputdivMobile = styled(Box)({
  marginTop: "45px",
  alignItems: "center",
  display: "flex",
  gap: "15px",
  padding: "0px 10px 0px 10px",
  "@media (max-width: 900px)": {
    flexDirection: "column",
    marginTop: "30px"
  },
  "@media (max-width: 550px)": {
    marginTop: "30px"
  },
  "@media (max-width: 350px)": {
    padding: "0px 10px 0px 10px"
  }
});

const LinkInputdivLink = styled(Box)({
  marginTop: "30px",
  padding: "0px 30px 0px 30px",
  "@media (max-width: 900px)": {
    marginTop: "10px"
  },
  "@media (max-width: 350px)": {
    padding: "0px 10px 0px 10px"
  }
});

const LinkInputdivMobileDiv = styled(Box)({
  padding: "0px 10px 0px 10px",
  marginTop: "30px",
  "@media (max-width: 900px)": {
    marginTop: "10px"
  },
  "@media (max-width: 350px)": {
    padding: "0px 10px 0px 10px"
  }
});

const MainLinkContiner = styled(Box)({
  marginTop:"0px"
});

const MainLinkContinerMobile = styled(Box)({
  marginTop:"0px"
});

const ErrorLinkPopupBox = styled(Box)({
  display: "flex",
  gap: "68px",
  justifyContent: "center",
  alignItems: "center",
  width: "100%"
});

const ErrorCopyLink = styled(Box)({
  display: "flex",
  gap: "68px",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "10px"
});

const ErrorCopyLinkMobile = styled(Box)({
  display: "flex",
  width: "100%",
  gap: "68px",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10px",
  marginBottom: "55px"
});

const ErrorLinkPopupBoxMobile = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: "68px",
  justifyContent: "center",
});


const NewLinkShareButton = styled(Button)({
  background: "#EBF5FE",
  color: "#0090CF",
  borderRadius: "8px",
  height: "44px",
  width: "235px",
  fontSize: "16px",
  fontWeight: 500,
  padding: "10px 16px 10px 16px",
  lineHeight: "24px",
  fontFamily: "Poppins",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#EBF5FE",
    color: "#0090CF"
  },
  "@media(max-width: 900px)": {
    fontSize: "14px"
  },
  "@media(max-width: 550px)": {
    width: "180px",
    height: "43px"
  }
});

const NewLinkShareButtonMobile = styled(Button)({
  background: "#EBF5FE",
  color: "#0090CF",
  borderRadius: "8px",
  width: "235px",
  fontSize: "16px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  fontWeight: 500,
  lineHeight: "24px",
  textTransform: "none",
  fontFamily: "Poppins",
  "&:hover": {
    color: "#0090CF",
    backgroundColor: "#EBF5FE"
  },
  "@media(max-width: 900px)": {
    fontSize: "14px"
  },
  "@media(max-width: 550px)": {
    width: "204px",
    height: "43px"
  }
});

const NewLinkShare = styled(Box)({
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
  padding: "0px 30px 0px 30px",
  gap: "20px",
  "@media (max-width: 900px)": {
    marginTop: "20px"
  },
  "@media (max-width: 350px)": {
    padding: "0px 10px 0px 10px",
    gap: "10px",
    marginTop: "10px",
  }
});

const NewLinkShareMobile = styled(Box)({
  marginTop: "10px",
  alignItems: "center",
  display: "flex",
  gap: "20px",
  padding: "0px 10px 0px 10px",
  "@media (max-width: 900px)": {
    marginTop: "20px"
  },
  "@media (max-width: 350px)": {
    padding: "0px 10px 0px 10px",
    marginTop: "10px",
    gap: "10px"
  }
});

const LinkShareButton = styled(Box)({
  textAlign: "center",
  margin: "28px 0px 30px 0px",
  "@media (max-width: 1250px)": {
    margin: "28px 0px 30px 0px"
  },
  "@media (max-width: 900px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    textAlign: "center",
    padding: "10px 20px 0px 20px"
  },
  "@media (max-width: 550px)": {
    margin: "10px 0px 30px 0px",
    padding: "0px 0px 0px 0px"
  }
});

const LinkShareButtonMobile = styled(Box)({
  textAlign: "center",
  margin: "28px 0px 30px 0px",
  "@media (max-width: 1250px)": {
    margin: "28px 0px 30px 0px"
  },
  "@media (max-width: 900px)": {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    justifyContent: "center",
    padding: "10px 20px 0px 20px"
  },
  "@media (max-width: 550px)": {
    padding: "0px 0px 0px 0px",
    margin: "10px 0px 0px 0px"
  }
});

const ContinueButton = styled(Button)({
  width: "40%",
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Poppins",
  padding: "10px 16px",
  gap: "8px",
  marginTop: "20px",
  borderRadius: "8px",
  background: "#C2E1FA",
  textTransform: "none",
  color: "#0090CF",
  "&:hover":{
    background: "#C2E1FA",
    color: "#0090CF"
  },
  "@media (max-width: 900px)": {
    width:"75%",
    marginRight: "0px"
  },
  "@media (max-width: 550px)": {
    marginTop: "8px",
    height: "41px"
  }
});

const ContinueButtonMobile = styled(Button)({
  width: "40%",
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Poppins",
  gap: "8px",
  marginTop: "20px",
  padding: "10px 16px",
  borderRadius: "8px",
  color: "#0090CF",
  background: "#C2E1FA",
  textTransform: "none",
  "&:hover":{
    background: "#C2E1FA",
    color: "#0090CF"
  },
  "@media (max-width: 900px)": {
    marginRight: "0px",
    width:"75%"
  },
  "@media (max-width: 550px)": {
    marginTop: "8px",
    height: "41px"
  }
});

const SendButton = styled(Button)({
  width: "30%",
  height: "50px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Poppins",
  borderRadius: "8px",
  backgroundColor: "#C2E1FA",
  textTransform: "none",
  color: "#0090CF",
  "&:hover":{
    background: "#C2E1FA",
    color: "#0090CF"
  },
  "@media (max-width: 900px)": {
    width: "30%",
    height: "44px"
  },
  "@media (max-width: 550px)": {
    width: "45%",
    height: "35px"
  }
});

const SendButtonMobile = styled(Button)({
  width: "30%",
  height: "50px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  padding: "10px 16px",
  fontWeight: 500,
  fontFamily: "Poppins",
  fontSize: "16px",
  backgroundColor: "#C2E1FA",
  borderRadius: "8px",
  color: "#0090CF",
  textTransform: "none",
  "&:hover":{
    color: "#0090CF",
    background: "#C2E1FA"
  },
  "@media (max-width: 900px)": {
    height: "44px",
    width: "30%"
  },
  "@media (max-width: 550px)": {
    height: "35px",
    width: "45%"
  }
});

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    padding: "20px 15px 15px 15px",
    fontWeight: 400,
    color: "#000000",
    border: '1px solid #A6B1B7',
    fontSize: "16px",
    margin: "6px -2px",
    width: "245px",
    borderRadius: "8px",
    "@media(max-width: 550px)":{
      padding: "10px 0px 5px 10px",
      width: "228px",
      fontSize: "14px"
    }
  },
  arrow: {
    color: "#ffffff",
    '&:before': {
      backgroundColor: "#ffffff",
      border: '1px solid #A6B1B7'
    }
  },
}))(Tooltip);

const CustomTooltipMobile = withStyles((theme) => ({
  tooltip: {
    width: "100%",
    backgroundColor: "#ffffff",
    padding: "20px 15px 5px 15px",
    color: "#000000",
    fontWeight: 400,
    margin: "6px -2px",
    border: '1px solid #A6B1B7',
    fontSize: "16px",
    borderRadius: "8px",
    "@media(max-width: 550px)":{
      padding: "10px 8px 5px 15px",
      width: "87%",
      fontSize: "14px",
    },
    "@media(max-width: 350px)":{
      padding: "11px 6% 5px 8%",
      width: "fit-content",
      fontSize: "14px",
      marginLeft: "0% !important",
      marginRight: "3%"
    },
  },
  arrow: {
    marginLeft: "0px !important",
    color: "#ffffff",
    '&:before': {
      border: '1px solid #A6B1B7',
      backgroundColor: "#ffffff"
    }
  },
}))(Tooltip);

const CustomLinkMobileScreen = styled(Box)({
  display: "none",
  "@media (max-width: 550px)": {
  position: "absolute",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  top: "50%",
  zIndex: 2,
  left: "50%",
  backgroundColor: "white",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  padding: "0px 0px 20px 0px",
  flexDirection: "column",
  display: "flex",
  width: "90%",
  "& .MuiDialog-paperFullWidth": {
    backgroundColor: "#FFF",
    borderRadius: "8px",
    padding: "0px 47px 0px 21px",
    boxSizing: "border-box",
    width: "100%",
  },
  },
});

const ErrorLoginAlertPopupLink = styled(Box)({
  minWidth: "50%",
  borderTop: "4px solid #DC2626",
  padding: "12px 10px",
  borderRadius: "4px",
  backgroundColor: "#FEE2E2",
  "@media (max-width: 1024px)": {
    minWidth: "80%"
  },
  "@media (max-width: 550px)": {
    minWidth: "0%",
    width: "100%"
  }
});

const CustomConfiramtionSuccess = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 2,
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "30px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  height: "250px",
  lineHeight: "2rem",
  width: "70%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "25px",
  "@media(max-width: 420px)": {
    gap: "10px",
    width: "75%",
    height: "200px",
    padding: "40px 20px 40px 20px"
  },
  "@media(max-width: 375px)": {
    gap: "10px",
    width: "78%",
    padding: "30px 20px 30px 20px"
  }
});

const PopUpIconMobile = styled("img")({
  width: "90px",
  height: "90px",
  "@media(max-width:375px)":{
    width: "55px",
    height: "52px"
  }
});

const SuccessMessageMobile = styled(Typography)({
  lineHeight: "27px",
  fontFamily: "Poppins, Inter, sans-serif",
  fontSize: "18px",
  maxWidth: "555px",
  fontWeight: 400,
  textAlign: "center",
  "@media(max-width: 435px)": {
    fontSize: "16px",
    lineHeight: "23px",
  }
});

const CustomLinkMobileButton = styled(Button)({
  width: "183px",
  padding: "10px 16px",
  borderRadius: "8px",
  textAlign: "center",
  fontFamily: "Poppins, Inter",
  backgroundColor: "#EBF5FE",
  fontWeight: 500,
  textTransform: "none",
  color: "white",
  background: "linear-gradient(to right, #34B9FC, #7B61FD)",
  "@media(max-width: 650px)": {
    width: "70%",
    margin: "15px 0px 15px 0px"
  },
  "@media(max-width: 375px)": {
    width: "100%"
  }
});
const IconImage = styled("img")({
  cursor: "pointer",
  width: "24px",
  height: "24px",
});
const DownloadContainer = styled(Box)({
  padding: "65px 50px",
  display:'flex',
  flexDirection:'column',
  gap:'30px',
  "@media(max-width: 600px)":{
    padding: "60px 30px 30px"
  }
})
const DownloadText = styled(Box)({
  fontFamily: "Poppins, Inter",
  fontWeight: 400,
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  borderBottom:"1px solid #000",
  paddingBottom:'10px',
  fontSize:'18px',
  "@media(max-width: 600px)":{
    fontSize:'16px'
  }
});
const CloseDownloadIcon = styled("img")({
  cursor: "pointer",
  position: "absolute",
  top: "10px",
  right:'10px'
});

const CustomBackdrop = styled(Modal)({
  justifyContent: "space-around",
  alignItems: "center",
  position: "absolute",
  display: "flex",
  width: "100%",
  background: "#ffffff"
});

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  }

  render(): ReactNode {
    return (
      <>
      <ContentWrapper navigation={this.props.navigation} profileImg={localStorage.getItem("user-info") || ""} handleProfileNavigate={this.handleprofileClicks} handleLogoutClick={this.toggleLogoutPopup}>
        <BorderWrapper>
          <LandingPageWrapper>
            <SelectTemplatePopUp
              data-test-id="create-popup"
              templates={this.state.templateOptions}
              isOpen={this.state.showSelectPopUp}
              isFetching={this.state.isFetching}
              closeModal={this.closeSelectPopUp}
              handleTemplateSelect={this.onTemplateSelect}
            />
            <CreateButton
              onClick={this.openSelectPopUp}
              data-test-id="create-btn"
            >
              <CreateSurveyImg src={createSurveyImg} />
              Create survey
            </CreateButton>
              {
                !this.state.searchTextValue &&
                <CreateButtonMobile
                  onClick={this.openSelectPopUp}
                >
                  <CreateSurveyImg src={createSurveyImg} />
                  Create survey
                </CreateButtonMobile>
              }
            {this.state.openPopup && <PopupBlurBackground />}
            {this.state.openPopup && (
              <CustomPopup>
                <CloseDivIcon>
                  <CloseIcon src={popupCrossIcon} onClick={this.handleClosePopup} />
                </CloseDivIcon>
                <MiddleContainer>
                  <MainContiner>
                    <form data-test-id="continueId" onSubmit={this.createSurveyName}>
                      <Heading>{configJSON.surveyTemplate}</Heading>
                      <HrBorder></HrBorder>
                      {this.state.validationsError !== '' && (
                        <ErrorPopupBox>
                          <ErrorLoginAlertPopup>
                            <ErrorLoginInput dangerouslySetInnerHTML={{__html: this.state.validationsError}} />
                          </ErrorLoginAlertPopup>
                        </ErrorPopupBox>
                      )}
                      <Inputdiv>
                        <LabelText>{configJSON.surveyName}</LabelText>
                        <Textfield
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={this.state.surveyName}
                          placeholder="Enter Name"
                          onChange={(e) => this.setSurveyName(e.target.value)}
                          error={this.state.surveyNameError}
                          data-test-id="surveyNameId"
                        />
                      </Inputdiv>
                      <Inputdiv>
                        <LabelText>{configJSON.propertyName}</LabelText>
                        <Textfield
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={this.state.propertyOwner}
                          placeholder="Enter Name"
                          onChange={(e) => this.setPropertyOwner(e.target.value)}
                          error={this.state.propertyOwnerError}
                          data-test-id="propertyOwnerId"
                        />
                      </Inputdiv>
                      <BorderTop></BorderTop>
                      <Buttondiv>
                        <CancelButton data-test-id="cancelId" onClick={this.handleClosePopup}>{configJSON.cancel}</CancelButton>
                        <ConfirmButton type="submit">{configJSON.continue}</ConfirmButton>
                      </Buttondiv>
                    </form>
                  </MainContiner>
                </MiddleContainer>
              </CustomPopup>
            )}
              {this.state.openDownloadPopup && 
              <>
              <PopupBlurBackground />
              <CustomPopup data-test-id="downloadSurveyPopupId" style={{ maxWidth: '576px' }}>
                <CloseDivIcon>
                  <CloseDownloadIcon src={popupCrossIcon} onClick={this.handlecloseDownloadPopup} />
                </CloseDivIcon>
                <DownloadContainer>
                  <DownloadText data-test-id="downloadReportFileId" onClick={this.handleDownloads}>
                    {configJSON.downloadAsPDF}
                    <IconImage src={downloadSurveyIcon} alt="" />
                  </DownloadText>
                  <DownloadText>
                    Download photos & videos as zip
                      {
                        this.state.downloadZipFileResp.data.uploaded_file_details.total_uploaded_files > 0 ?
                          <IconImage data-test-id="downloadZipFileId" src={downloadSurveyIcon} style={{cursor:'pointer'}} alt=""
                            onClick={this.handleZipFileLinkClick} /> :
                          <IconImage src={downloadSurveyIcon} style={{cursor:'not-allowed'}} alt=""
                          />
                      }

                  </DownloadText>
                </DownloadContainer>
              </CustomPopup>
              </>
              
              }
              <CustomBackdrop open={this.state.downloadLoader}>
                <CircularProgress color="inherit" />
              </CustomBackdrop>
        {this.state.screenSize <= 550 ? (
        <Modal open={this.state.openLinkSharePopup} data-test-id="popupModalId">
         <CustomLinkMobileScreen>
           <MiddleContainerMobile>
          <MainLinkContinerMobile>
            <form data-test-id="sendLinkId" onSubmit={this.handleSentLinkShareEmail}>
              {this.state.validationsError !== '' && (
                <ErrorLinkPopupBoxMobile>
                  <ErrorLoginAlertPopupMobile>
                    <ErrorLoginInputMobile>{this.state.validationsError}</ErrorLoginInputMobile>
                  </ErrorLoginAlertPopupMobile>
                </ErrorLinkPopupBoxMobile>
              )}
               {this.state.copySuccessMesssage !== "" && (
                  <ErrorCopyLinkMobile>
                    <ErrorCopyInput>{this.state.copySuccessMesssage}<img src={right} alt="right"/></ErrorCopyInput>
                  </ErrorCopyLinkMobile>
              )}
              <LinkInputdivMobile>
                <TextfieldMobile
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={this.state.emailShared}
                  placeholder="Enter your email"
                  onChange={(e) => this.sendLinkShareEmail(e.target.value)}
                  error={this.state.emilShareError}
                  data-test-id="emailLinkId"
                />
                <SendButtonMobile type="submit">{configJSON.sendMail}</SendButtonMobile>
              </LinkInputdivMobile>
              <LinkInputdivMobileDiv>
              <InputField>
                <LinkTextField>{this.state.initialLinkToCopy}</LinkTextField>
                  <IconImages src={copyIcon} alt="copy"
                    onClick={this.copyToClipboard} data-test-id="copyLinkId" />
              </InputField>
              </LinkInputdivMobileDiv>
              <NewLinkShareMobile>
                <NewLinkShareButtonMobile data-test-id="revokeId" onClick={this.handleRevokeAccess}>{configJSON.copyEmail}</NewLinkShareButtonMobile>
                <CustomTooltipMobile title={configJSON.tooltipMessage} placement="bottom" arrow
                onClose={this.handleTooltip}
                open={this.state.openMobileTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                >
                  <img src={infoIcon} alt="info" onClick={this.handleTooltip} data-test-id="tooltipId"/>
                </CustomTooltipMobile>
              </NewLinkShareMobile>
              <LinkShareButtonMobile>
                <ContinueButtonMobile data-test-id="linkCloseId" onClick={this.handleLinkShareClose}>{configJSON.close}</ContinueButtonMobile>
              </LinkShareButtonMobile>
            </form>
          </MainLinkContinerMobile>
          </MiddleContainerMobile>
         </CustomLinkMobileScreen>
        </Modal>
         ) : (
     <>
            {this.state.openLinkSharePopup && (
              <>
              <PopupBlurBackground />
              <CustomPopup data-test-id="popupIds">
                <MiddleContainer>
                  <MainLinkContiner>
                    <form data-test-id="sendLinkId" onSubmit={this.handleSentLinkShareEmail}>
                      {this.state.validationsError !== '' && (
                        <ErrorLinkPopupBox>
                          <ErrorLoginAlertPopupLink>
                            <ErrorLoginInput>{this.state.validationsError}</ErrorLoginInput>
                          </ErrorLoginAlertPopupLink>
                        </ErrorLinkPopupBox>
                      )}
                      {this.state.copySuccessMesssage !== "" && (
                        <ErrorCopyLink>
                          <ErrorCopyInput>{this.state.copySuccessMesssage}<img src={right} alt="right"/></ErrorCopyInput>
                        </ErrorCopyLink>
                      )}
                      <LinkInputdiv>
                        <Textfield
                          fullWidth
                          variant="outlined"
                          type="text"
                          value={this.state.emailShared}
                          placeholder="Enter email address here"
                          onChange={(e) => this.sendLinkShareEmail(e.target.value)}
                          error={this.state.emilShareError}
                          data-test-id="emailLinksId"
                        />
                        <SendButton type="submit">{configJSON.sendMail}</SendButton>
                      </LinkInputdiv>
                      <LinkInputdivLink>
                      <InputField>
                          <LinkTextField>{this.state.initialLinkToCopy}</LinkTextField>
                            <IconImages src={copyIcon} alt="copy"
                              onClick={this.copyToClipboard} />
                       </InputField>
                          </LinkInputdivLink>
                      <NewLinkShare>
                        <NewLinkShareButton onClick={this.handleRevokeAccess}>{configJSON.copyEmail}</NewLinkShareButton>
                        <CustomTooltip title={configJSON.tooltipMessage} placement="bottom" arrow>
                        <img src={infoIcon} alt="info" />
                        </CustomTooltip>
                      </NewLinkShare>
                      <LinkShareButton>
                       <ContinueButton onClick={this.handleLinkShareClose}>{configJSON.close}</ContinueButton>
                      </LinkShareButton>
                    </form>
                  </MainLinkContiner>
                </MiddleContainer>
              </CustomPopup>
              </>
             )}
          </>
         )}
            {this.state.screenSize >550 && this.state.successLinkPopup && <SuccessDeletePopup 
            confirmationText={`${configJSON.linkSuccessMessage} ${this.state.emailShared}`}
            buttonText={configJSON.doneMessage}
            handleCloseSucessDeletePopup={this.handleLinkSuccessClose}
            />} 
                
             <EmptyHistory
              data-test-id="empty-history-comp"
              handleFormateDate={this.handleFormateDate}
              openDatePicker={this.state.openDatePicker} handleDateChange={this.handleDateChange}
              showDateRangeText={this.state.showDateRangeText}
              handleDatePicker={this.handleDatePicker} 
              handleSaveDatePicker={this.handleSaveDatePicker}
              handleCloseDatePicker={this.handleCloseDatePicker} 
              onSelectPopup={this.openSelectPopUp}
              months={this.months}
              createdSurveyLists={this.state.surveyHistoryListResp.data}
              openSurveyDetailPopup={this.state.openSurveyDetailPopup}
              surveyPopupData={this.state.surveyPopupData}
              handleOpenSurveyDetail={this.handleOpenSurveyDetail}
              handleCloseSurveyDetail={this.handleCloseSurveyDetail}
              handleDeleteSurvey={this.handleDeleteSurvey}
              handleEditSurvey={this.handleEditSurvey}
              openDeleteSurveyPopup={this.state.openDeleteSurveyPopup}
              handleCloseDeleteSurveyPopup={this.handleCloseDeleteSurveyPopup}
              handleConfirmDeleteSurvey={this.handleConfirmDeleteSurvey}
              successDeletePopup={this.state.successDeletePopup}
              handleCloseSucessDeletePopup={this.handleCloseSucessDeletePopup}
              showEmptyHistory={this.state.showEmptyHistory}
              totalPages ={this.state.surveyHistoryListResp.meta.total_pages}
              handleChangePagination={this.handleChangePagination}
              searchTextValue={this.state.searchTextValue}
              handleChangeSearchText={this.handleChangeSearchText}
              surveyLoader = {this.state.surveyLoader}
              handleLinkShare={this.handleLinkShare}
              handleDownloadSurvey={this.handleDownloadSurvey}
              rangees = {this.state.rangees}
              startDate = {this.state.startDate}
              endDate= {this.state.endDate}
              formatMonthAndDate={this.formatMonthAndDate}
               />
          </LandingPageWrapper>
          <LogoutPopup
            open={this.state.openLogoutModal}
            BackdropComponent={Backdrop}
            closeAfterTransition
            BackdropProps={{
              timeout: 500
            }}
          >
            <LogoutModalParaContainer in={this.state.openLogoutModal}>
              <Box>
                <img
                  src={logoutModalIcon}
                  alt="emailIcon"
                  width={35.89}
                  height={26.67}
                />
                <Box>
                  <LogoutParaOne>{configJSON.logoutParaOne}</LogoutParaOne>
                  <LogoutParaOne>{configJSON.logoutParaTwo}</LogoutParaOne>
                </Box>
                <LogoutButtonContainer>
                  <CancelLogoutButton
                    data-test-id="logoutModalId"
                    variant="contained"
                    onClick={this.toggleLogoutPopup}
                  >
                    {configJSON.cancel}
                  </CancelLogoutButton>
                  <LogoutButton
                    data-test-id="logoutId"
                    variant="contained"
                    onClick={this.handleLogout}
                  >
                    {configJSON.logout}
                  </LogoutButton>
                </LogoutButtonContainer>
              </Box>
            </LogoutModalParaContainer>
          </LogoutPopup>
        </BorderWrapper>
      </ContentWrapper>
      {this.state.screenSize <= 550 && (
      <Modal open={this.state.successLinkPopup}>
        <CustomConfiramtionSuccess>
          <PopUpIconMobile src={confirmationIcon} />
          <SuccessMessageMobile id="alert-dialog-description">{`${configJSON.linkSuccessMessage} ${this.state.emailShared}`}</SuccessMessageMobile>
          <CustomLinkMobileButton
            data-test-id="success-popup-btn"
            onClick={this.handleLinkSuccessClose}
          >
            {configJSON.doneMessage}
          </CustomLinkMobileButton>
        </CustomConfiramtionSuccess>
      </Modal>)}
        <Box id={"savePdfId"} display={"none"} width={"1440px"}>
          <SaveAsPdf
            navigation={this.props.navigation}
            id={this.props.id}
            surveyName={configJSON.doneMessage}
            created={configJSON.createdDatePdf}
            lastEdited={configJSON.lastEditedPdf}
            createdBy={configJSON.createdByPdf}
            owner={configJSON.ownerPdf}
            matchId={configJSON.doneMessage}
            fields={configJSON.doneMessage}
            fieldValues={configJSON.doneMessage}
            sectionPdfDetails={this.state.saveAsReportPdfDetails}
          />
        </Box>
      </>
    );
  }
}
// Customizable Area End