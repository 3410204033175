import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendRequest } from "../../forgot-password/src/utils";
import storage from "../../../framework/src/StorageProvider.web";
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { getNavigationMessage } from "../../../components/src/toolkit";
import jsPDF, { HTMLOptions } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

export type TemplateSelectOption = {
  id: number;
  name: string;
};
export interface ValidResponseType {
  data: object;
}
export interface InvalidResponseType {
  errors: string;
}
export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
}
interface CreateSurveyTemplate {
  data: {
    id: number,
    type: string,
    attributes: {
      id: number,
      survey_template_id?: string,
      survey_name: string,
      date_created: string,
      last_edited: string,
      created_by: string,
      owner: string,
      progress: null,
      user_survey_sections: [
        {
          id: number,
          user_survey_section_name: string,
          user_survey_sub_sections: [
            {
              id: number,
              user_survey_sub_section_name: string,
              user_survey_sub_section_details: [
                {
                  id: number,
                  field_name: string,
                  field_value: null,
                  photos: []
                },
                {
                  id: number,
                  field_name: string,
                  field_value: null,
                  photos: []
                }
              ]
            }
          ]
        }
      ]
    }
  },
  meta: {
    message: string
  }
}

export interface ApidataResponseMock {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface UserSurvey {
  id: string;
  type: string;
  attributes: {
    id: number;
    survey_name: string;
    date_created: string;
    last_edited: string;
    created_by: string;
    owner: string;
    progress: any;
    user_survey_sections: UserSurveySection[];
  };
}

export interface UserSurveySection {
  id: number;
  user_survey_section_name: string;
  user_survey_sub_sections: UserSurveySubSection[];
}

export interface UserSurveySubSection {
  id: number;
  user_survey_sub_section_name: string;
  user_survey_sub_section_details: UserSurveySubSectionDetail[];
}

export interface UserSurveySubSectionDetail {
  id: number;
  field_name: string;
  field_value: any;
  photos: string[];
}

interface HistoryListApiResponse {
  data: UserSurvey[];
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
  };
}

interface SurveyPopupDataInterface{
  id: string;
  type: string;
  attributes: {
    id: number;
    survey_name: string;
    date_created: string;
    last_edited: string;
    created_by: string;
    owner: string;
    progress: any;
    user_survey_sections: UserSurveySection[];
  };
}

interface ShareEmailLink {
  data: {
      is_link_active: boolean;
      link_token: string;
      link_share_id: number;
      message: string;
  }
}
interface DownloadZipFileResponse {
  data: {
      uploaded_file_details:{
        uploaded_photos: number,
        uploaded_videos: number,
        total_uploaded_files: number
      }
  }
}
interface DownloadZipFileLinkResponse {
  data: {
      uploaded_files_winzip:string
  }
}
interface GenerateLink {
  data : {
  shareable_link: string;
  is_link_active: boolean;
  message: string;
  }
}

export interface DateRangeProp {
  startDate: Date;
  endDate: Date;
  key: string;
}
interface DateRangeItem {
  selection: DateRangeProp;
}

export interface SaveReportAsPdf {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      survey_name: string;
      date_created: string;
      last_edited: string;
      created_by: string;
      owner: string;
      progress: string;
      user_survey_sections: Array<SurveyReportSections>;
    }
  }
}

export interface SurveyReportSections {
  id: number;
  user_survey_section_name: string;
  is_survey_subsection_present: boolean;
  user_survey_sub_sections: Array<SurveyReportSubSection>;
}

export interface SurveyReportSubSection {
  id: number;
  user_survey_sub_section_name: string;
  user_survey_sub_section_details: Array<SurveyReportSubSectionDetails>;
}

export interface SurveyReportSubSectionDetails {
  id: number;
  field_name: string;
  field_value: string;
  field_value_multiple: string[];
  field_type: string;
  field_options: string[];
  photos: Array<MediaReportFiles>;
  videos: Array<MediaReportFiles>;
}

export interface MediaReportFiles {
    id: number;
    filename: string;
    content_type: string;
    description: null;
    created_at: string;
    byte_size: number;
    url: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showSelectPopUp: boolean;
  isFetching: boolean;
  openDatePicker:boolean;
  showDateRangeText:boolean;
  selection: any,
  templateOptions: TemplateSelectOption[];
  error: string;
  openPopup: boolean;
  surveyName: string;
  surveyNameError: boolean;
  validationError: string;
  propertyOwner: string;
  propertyOwnerError: boolean;
  templateId: number;
  validationsError: string;
  createUserSurvey: CreateSurveyTemplate;
  surveyHistoryListResp:HistoryListApiResponse;
  surveyHistoryListErrResp:string;
  createdSurveyLists:UserSurvey|[];
  perPageSurveys:string;
  noOfPages:string;
  openSurveyDetailPopup:boolean,
  surveyPopupData:object,
  deleteSurveyErrResp:string;
  openDeleteSurveyPopup:boolean;
  createdSurveyId:string;
  successDeletePopup:boolean;
  showEmptyHistory:boolean;
  openLogoutModal: boolean;
  profileData:any;
  totalPages: number;
  pageNumber:number;
  searchTextValue: string;
  surveyLoader: boolean;
  openLinkSharePopup: boolean;
  emailShared: string;
  emilShareError: boolean;
  successLinkPopup: boolean;
  screenSize: number;
  generateLinkShare: GenerateLink;
  linkShareId: number;
  initialLinkToCopy: string;
  copySuccessMesssage: string;
  openMobileTooltip: boolean;
  openDownloadPopup: boolean;
  rangees: DateRangeProp[];
  startDate: Date | null;
  pickerStartDate: Date | null;
  pickerEndDate: Date | null;
  endDate: Date | null;
  shareEmailLink: ShareEmailLink;
  downloadZipFileResp: DownloadZipFileResponse;
  downloadZipFileId: string
  downloadWinZipLinkResp:DownloadZipFileLinkResponse
  saveAsReportPdfDetails: SaveReportAsPdf;
  downloadLoader: boolean;
  baseURL:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.handleResize = this.handleResize.bind(this);

    this.inputRef = React.createRef();
    this.state = {
      profileData: [],
      showSelectPopUp: false,
      isFetching: false,
      templateOptions: [],
      error: "",
      openPopup: false,
      surveyName: "",
      surveyNameError: false,
      validationError: "",
      propertyOwner: "",
      propertyOwnerError: false,
      templateId: 0,
      validationsError: "",
      openDatePicker:false,
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
      showDateRangeText:false,
      createUserSurvey: {
        data: {
          id: 0,
          type: "",
          attributes: {
            id: 0,
            survey_name: "",
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            progress: null,
            user_survey_sections: [
              {
                id: 0,
                user_survey_section_name: "",
                user_survey_sub_sections: [
                  {
                    id: 0,
                    user_survey_sub_section_name: "",
                    user_survey_sub_section_details: [
                      {
                        id: 0,
                        field_name: "",
                        field_value: null,
                        photos: []
                      },
                      {
                        id: 0,
                        field_name: "",
                        field_value: null,
                        photos: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        },
        meta: {
          message: ""
        }
      },
      surveyHistoryListResp:{
        data: [],
        meta: {
        total_pages: 0,
        total_count: 0,
        current_page: 0
        }
      },
      surveyHistoryListErrResp:"",
      createdSurveyLists:[],
      perPageSurveys:"",
      noOfPages:"",
      openSurveyDetailPopup:false,
      surveyPopupData:{},
      deleteSurveyErrResp:"",
      openDeleteSurveyPopup:false,
      createdSurveyId:"",
      successDeletePopup:false,
      showEmptyHistory:false,
      openLogoutModal: false,
      totalPages: 0,
      pageNumber:0,
      searchTextValue: "",
      surveyLoader: true,
      openLinkSharePopup: false,
      emailShared: "",
      emilShareError: false,
      successLinkPopup: false,
      screenSize: 600,
      generateLinkShare:{
        data: {
        shareable_link: "",
        is_link_active: false,
        message: ""
      }
      },
      linkShareId: 0,
      initialLinkToCopy: "",
      copySuccessMesssage: "",
      openMobileTooltip:  false,
      openDownloadPopup: false,
      rangees: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ],
      startDate:  null,
      endDate: null,
      pickerStartDate: null,
      pickerEndDate: null,
      downloadZipFileId:"",
      shareEmailLink: {
        data: {
          is_link_active: false,
          link_token: "",
          link_share_id: 0,
          message: ""
        }
      },
      downloadZipFileResp:{
        data: {
          uploaded_file_details: {
            total_uploaded_files: 0,
            uploaded_photos: 0,
            uploaded_videos: 0
          }
        } 
      },
      downloadWinZipLinkResp:{
        data: {
          uploaded_files_winzip:""
        }
      },
      saveAsReportPdfDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            survey_name: "",
            date_created: "",
            last_edited: "",
            created_by: "",
            owner: "",
            progress: "",
            user_survey_sections: []
          }
        }
      },
      downloadLoader: false,
      baseURL:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.templateOptionsMessageId
    ) {
      this.setState({ isFetching: false });
      const successMessage = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!successMessage) {
        this.setState({ error: "An unexpected error occured" });
        this.closeSelectPopUp();
        return;
      }

      this.setState({ templateOptions: successMessage.survey_templates });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailCallBack(apiRequestCallId, responseJson);
      }
    }

    switch (dataMessage) {
      case this.templateOptionsMessageId:
        this.handleTemplateOptionsMessage(message);
        break;
  
      case this.surveyHistoryListApiCallId:
        this.handleSurveyHistoryListMessage(message);
        break;
  
      case this.deleteSurveyApiCallId:
        this.handleDeleteSurveyMessage(message);
        break;
  
      default:
        this.handleDefaultMessage(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  protected inputRef: React.RefObject<HTMLInputElement>;
  templateOptionsMessageId: string = "";
  postTemplateSurveyApiCallId: string = "";
  getSurveyDetailsCallId: string = "";
  surveyHistoryListApiCallId: string = "";
  deleteSurveyApiCallId: string = "";
  getProfileApiCallId: string = ""
  postGenerateInitialLink: string = "";
  postLinkShareEmailApiCallId: string = "";
  revokeAccessCopyApiCallId: string = "";
  downloadZipFileApiCallId: string = "";
  downloadZipFileDataApiCallId: string = "";
  getSaveReportPdfApiCallId: string = "";
  async componentDidMount(): Promise<void> {
    const token = await getStorageData("auth-token");
    if (!token) {
      this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
      return;
    }
    this.getSurveyHistoryList();
    this.getProfile();
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(
    __: Readonly<Props>,
    prevState: Readonly<S>,
    _?: SS | undefined
  ): void {
    if (
      prevState.showSelectPopUp !== this.state.showSelectPopUp &&
      window.innerWidth < 650
    ) {
      this.preventScroll();
    }
    if (this.state.openPopup < prevState.openPopup && !this.state.openPopup) {
      this.setState({
        validationsError: '',
      })
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  
  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  }


  daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];





  handleDatePicker = ()=>{
    this.setState({
      openDatePicker:true,
    })
  }

  handleDateChange = (item: DateRangeItem) => {
    const { startDate, endDate } = item.selection;
    this.setState({ rangees: [item.selection], startDate, endDate });
  };

  handleCloseDatePicker = () => {
    const currentDate = new Date();
    this.setState({
      surveyLoader: true,
      rangees: [
        {
          startDate: currentDate,
          endDate: currentDate,
          key: 'selection'
        }
      ],
      startDate: null,
      endDate: null,
      openDatePicker: false,
      pickerEndDate: null,
      pickerStartDate: null
    },
    () => {
      this.getSurveyHistoryList();
    }
    );
  }

  toggleLogoutPopup = () => {
    this.setState({
      openLogoutModal: !this.state.openLogoutModal,
    });
  };

  handleLogout = async () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    await storage.clearStorage();
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  handleSaveDatePicker = ()=>{
    this.setState({
      openDatePicker: false,
      surveyLoader: true,
      pickerStartDate: this.state.startDate,
      pickerEndDate: this.state.endDate
    },
      () => {
        this.getSurveyHistoryList(this.state.pickerStartDate as Date, this.state.pickerEndDate as Date);
      }
    )
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  openSelectPopUp = () => {
    this.setState({ showSelectPopUp: true });
    this.fetchSelectTemplateOptions();
  };

  closeSelectPopUp = () => {
    this.setState({ showSelectPopUp: false });
  };

  private fetchSelectTemplateOptions = () => {
    sendRequest(
      "/bx_block_survey/survey_template/survey_templates",
      "GET",
      id => (this.templateOptionsMessageId = id)
    );
    this.setState({ isFetching: true });
  };

  convertDate(date: Date) {
    let givenDate = new Date(date);
    let days = givenDate.getDate();
    let month = givenDate.getMonth() + 1;
    let year = givenDate.getFullYear();

    let formattedDay = days < 10 ? `0${days}` : days;
    let formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  }
   getSurveyHistoryList = async (startDate?: Date, endDate?: Date ) => {  
      this.surveyHistoryListApiCallId = await this.apiCallData({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: `bx_block_survey/users_survey?per_page=10&page=${this.state.pageNumber}&search_query=${this.state.searchTextValue}${startDate && endDate || this.state.pickerEndDate ? '&start_date='+this.convertDate(startDate as Date ?? this.state.pickerStartDate ) + '&end_date=' + this.convertDate(endDate as Date ?? this.state.pickerEndDate ): ""}`,

      });
  };

  getZipFileData = async () => {
    this.downloadZipFileApiCallId = await this.apiCallData({
      contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: `bx_block_survey/user_survey_media/uploaded_files_details?user_survey_id=${this.state.downloadZipFileId}`,
    })
  }

  deleteCreatedSurvey = async (createdSurveyId:string)=>{
    const token = await storage.get("auth-token");

    sendRequest(
      `/bx_block_survey/users_survey/${createdSurveyId}`,
      "DELETE",
      id => (this.deleteSurveyApiCallId = id),
      null,
      {
        token
      }
    )
  }

  private preventScroll() {
    const root = document.getElementById("root");
    if (this.state.showSelectPopUp) {
      // @ts-ignore
      root.style.overflow = "hidden";
    } else {
      // @ts-ignore
      root.style.overflow = "unset";
    }
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailCallBack = (
    apiRequestCallID: string,
    responseJSON: InvalidResponseType
  ) => {
    if (apiRequestCallID === this.postTemplateSurveyApiCallId) {
      this.setState({
        validationsError: responseJSON.errors
      });
    }

    if(apiRequestCallID === this.getSurveyDetailsCallId) {
      this.setState({
        validationError: responseJSON.errors
      })
    }
  };

  apiSuccessCallBacks = async (
    apiRequestCallID: string,
    responseJson: CreateSurveyTemplate & HistoryListApiResponse & GenerateLink & ShareEmailLink & DownloadZipFileResponse & DownloadZipFileLinkResponse
  ) => {
    await storage.set("createSurveyResponse",JSON.stringify(responseJson))
    if (apiRequestCallID === this.postTemplateSurveyApiCallId) {
      this.setState(
        {
          createUserSurvey:responseJson,
          openPopup: false
        },()=>{
          this.onTemplateSelectId(responseJson.data.attributes.id,responseJson.data.attributes.user_survey_sections[0].id);
        });
    }

    if(apiRequestCallID === this.getSurveyDetailsCallId) {
    await storage.set("createUserResponse",JSON.stringify(responseJson));
      this.setState({
        createUserSurvey: responseJson
      }, ()=>{
        this.props.navigation.navigate("CustomForm", {
          sectionId: responseJson.data.attributes.user_survey_sections[0].id,
          templateId: responseJson.data.attributes.survey_template_id,
          surveyId: responseJson.data.id,
        })
      })
    }

    if (apiRequestCallID === this.surveyHistoryListApiCallId) {
      this.setState({
            showEmptyHistory:true,
            surveyLoader: false,
            surveyHistoryListResp:responseJson
      },()=>{
        this.handlePagination();
      });
    }

    if (apiRequestCallID === this.postGenerateInitialLink) {
      this.setState({
          generateLinkShare: responseJson,
          initialLinkToCopy: responseJson.data.shareable_link,
          openLinkSharePopup: true,
          copySuccessMesssage: "",
          validationsError:""
      });
    }

    if (apiRequestCallID === this.postLinkShareEmailApiCallId) {
       this.setState({
          openLinkSharePopup: false,
          emilShareError: false,
          validationsError:"",
          copySuccessMesssage: "",
          successLinkPopup: true,
          shareEmailLink: responseJson
       });
     }
     
     if (apiRequestCallID === this.revokeAccessCopyApiCallId) {
       this.setState({
         generateLinkShare: responseJson,
         initialLinkToCopy: responseJson.data.shareable_link,
         copySuccessMesssage: "",
         validationsError:"",
         emilShareError: false
        });
      }

     
     if (apiRequestCallID === this.downloadZipFileApiCallId) {
      this.setState({
        downloadZipFileResp: responseJson
      }, ()=>{
        this.handleDownloadZipFiles()
      });
      }
      if(apiRequestCallID === this.downloadZipFileDataApiCallId){
        this.setState({
          downloadWinZipLinkResp: responseJson
        })

      }

  };

  getReportPdfDetails = (apiRequestCallId: string, responseJson: SaveReportAsPdf) => {
    if (apiRequestCallId === this.getSaveReportPdfApiCallId) {
      this.setState({
        saveAsReportPdfDetails: responseJson,
        showSelectPopUp: false
      });
    }
  };

  apiCallData = async (data: ApidataResponseMock) => {
    const token = await storage.get("auth-token");
    let { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  createSurveyName = async (eventSubmitTemplate: React.FormEvent<HTMLFormElement>) => {
    eventSubmitTemplate.preventDefault();
    const { templateId, surveyName, propertyOwner } = this.state;

    this.setState({
      validationsError: "",
      surveyNameError: false,
      propertyOwnerError: false
    });

    if (surveyName === "" && propertyOwner === "") {
      this.setState({
        validationsError: 'Please enter <span>Survey Name</span> & <span>Property Owner</span>',
        surveyNameError: true,
        propertyOwnerError: true
      });
    } else if (surveyName === "") {
      this.setState({
        validationsError: "Please enter <span>Survey Name</span>",
        surveyNameError: true,
        propertyOwnerError: false
      });
    } else if (propertyOwner === "") {
      this.setState({
        validationsError: "Please enter <span>Property Owner Name</span>",
        surveyNameError: false,
        propertyOwnerError: true
      });
    } else {
      this.setState({validationsError: ""})
      let apiBody = {
        survey_template_id: templateId,
        survey_name: surveyName,
        owner_name: propertyOwner,
        status: "Started"
      };
      this.postTemplateSurveyApiCallId = await this.apiCallData({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.createSurveyApiEndPoint,
        body: apiBody
      });
    }
  };

  onTemplateSelectId = (surveyId:number,sectionId:number) => {
    let createUserSurvey = JSON.stringify(this.state.createUserSurvey)
    storage.set("createUserResponse",createUserSurvey);
    this.props.navigation.navigate("CustomForm", { templateId: this.state.templateId,surveyId,sectionId});
  };

  onTemplateSelect = (templateId: number) => {
    this.setState({
      templateId: templateId,
      openPopup: true,
      showSelectPopUp: false
    })
  };

  handleClosePopup = () => {
    this.setState({
      openPopup: !this.state.openPopup,
      surveyName: "",
      propertyOwner: "",
      surveyNameError: false,
      propertyOwnerError: false,
      validationsError: ""
    });
  };
  handlecloseDownloadPopup = () => {
    this.setState({
      openDownloadPopup: false
    })
  }
  setSurveyName = (surveyName: string) => {
    this.setState({
      surveyName: surveyName,
      surveyNameError: false
    });
  };

  setPropertyOwner = (ownerName: string) => {
    this.setState({
      propertyOwner: ownerName,
      propertyOwnerError: false
    });
  };

  handleOpenSurveyDetail = (data: SurveyPopupDataInterface) => {
    const surveyPopupData ={
      id: data?.id,
      survey_name: data?.attributes.survey_name,
      date_created: data?.attributes.date_created,
      last_edited:  data?.attributes.last_edited,
      created_by:  data?.attributes.created_by,
      owner:  data?.attributes.owner,
      progress:  data?.attributes.progress,
    }
    this.setState({
      openSurveyDetailPopup: true,
      surveyPopupData,
    });
  };

  handleCloseSurveyDetail = () => {
    this.setState({
      openSurveyDetailPopup: false,
    });
  };

  handleDownloadSurvey = (id:string) => {
    this.setState({
      downloadZipFileId: id,
      openDownloadPopup: true
    }, ()=>{
      this.getZipFileData();
      this.getSavePdfDetails();
    })
  }
  handleDeleteSurvey = (id: string) => {
    this.setState({
      createdSurveyId:id,
      openDeleteSurveyPopup:true,
    },()=>{
      this.getSurveyHistoryList();
    });
  }

  handleEditSurvey = async(id: string) => {
    this.getSurveyDetailsCallId = await this.apiCallData({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `bx_block_survey/users_survey/${id}`
    });
  }

  handleCloseDeleteSurveyPopup = () => {
    this.setState({
      openDeleteSurveyPopup: false,
    })
  }

  handleConfirmDeleteSurvey = () => {
    const { createdSurveyId } = this.state;
    this.deleteCreatedSurvey(createdSurveyId);
    this.setState({
      openDeleteSurveyPopup:false,
    })
  }

  handleCloseSucessDeletePopup = ()=>{
    this.setState({
      successDeletePopup:false,
    })
  }
  //-----------------------------------------------------*****------------------------------------------
  getProfile = () => {
    const token = localStorage.getItem("auth-token");
    const profileId = localStorage.getItem("user-id");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.profilepicApiEndPoint}${profileId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };
  handleprofileClicks = () => {
    this.props.navigation.navigate("UserProfileAdvance");
  };
  handleTemplateOptionsMessage(message: Message) {
    this.setState({ isFetching: false });
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (!successMessage) {
      this.setState({ error: "An unexpected error occurred" });
      this.closeSelectPopUp();
      return;
    }
  
    this.setState({ templateOptions: successMessage.survey_templates });
  }
  
  handleSurveyHistoryListMessage(message: Message) {
    const successHistoryMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (!successHistoryMessage) {
      this.setState({ surveyHistoryListErrResp: "An unexpected error occurred" });
    }
  
    this.setState({
      showEmptyHistory: true,
      surveyHistoryListResp: successHistoryMessage,
      createdSurveyLists: successHistoryMessage.data,
    });
  }
  
  handleDeleteSurveyMessage(message: Message) {
    const successDeleteMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (!successDeleteMessage) {
      this.setState({ deleteSurveyErrResp: "An unexpected error occurred" });
    }
  
    this.getSurveyHistoryList();
    this.setState({
      successDeletePopup: true,
    });
  }
  
  handleDefaultMessage(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailCallBack(apiRequestCallId, responseJson);
      }
  
      if (apiRequestCallId === this.getProfileApiCallId) {
        this.setState({baseURL:responseJson.host})
        const photoUrl =
          responseJson.data && responseJson.data.data.attributes?.photo?.url;
  
        if (photoUrl) {
          localStorage.setItem("user-info", photoUrl);
        }
      }
      this.getReportPdfDetails(apiRequestCallId, responseJson)
    }
    // Additional default handling logic goes here
  }

  handleChangePagination = (_:object,page: number) =>{
      this.setState({
        pageNumber: page,
        surveyLoader: true
      },()=>{
        this.getSurveyHistoryList();
      });
    }

    handlePagination = () => {
      const surveyHistoryListRespFull = this.state.surveyHistoryListResp;
      if (surveyHistoryListRespFull.data.length < 1) {
        this.setState(prevState => ({
          pageNumber: Math.max(1, prevState.pageNumber - 1)
        }));
      }
    }
  handleChangeSearchText = (searchEvent: React.ChangeEvent<HTMLInputElement>) =>{
    const searchHistory = searchEvent.target.value;
    this.setState({
     searchTextValue: searchHistory,
     surveyLoader: true
    },()=>{
     this.getSurveyHistoryList();
    });  
   }


  
   handleLinkShare = (userSurveyId: number) =>{
    let linkShareId = userSurveyId;
    this.setState({
      linkShareId: linkShareId
    }, ()=>{
       this.generateInitialLink();
    });
   }

   sendLinkShareEmail = (emailLink: string) => {
    let { emailShared } = this.state;
    let emailAddress = configJSON.emailRegx;
    if (!emailShared.match(emailAddress)) {
    this.setState({
      emailShared: emailLink,
      emilShareError: true,
      validationsError:"The email address you entered is not valid.",
      copySuccessMesssage: ""
    });
  }
    this.setState({
      emailShared: emailLink,
      emilShareError: false,
      validationsError:"",
      copySuccessMesssage: ""
    });                
   }


   handleLinkSuccessClose = () =>{
    this.setState({
      successLinkPopup: false,
      openLinkSharePopup: false,
      linkShareId: 0,
      emilShareError: false,
      validationsError: "",
      copySuccessMesssage: "",
      emailShared: "",
      initialLinkToCopy: ""
    });
   }

   handleLinkShareClose = () =>{
    this.setState({
      openLinkSharePopup: false,
      linkShareId: 0,
      emilShareError: false,
      validationsError: "",
      copySuccessMesssage: "",
      emailShared: "",
      initialLinkToCopy: ""
    });
   }

   generateInitialLink = async () => {
    let {linkShareId} = this.state;
      let apiBody = {
        link_share_id: linkShareId
    }
      this.postGenerateInitialLink = await this.apiCallData({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.generateInitialLinkEndPoint,
        body: apiBody
      });
  };

  handleSentLinkShareEmail = async(eventLinkShare: React.FormEvent<HTMLFormElement>) =>{
    eventLinkShare.preventDefault();
    let { linkShareId, emailShared } = this.state;
    let emailAddress = configJSON.emailRegx;
    if (!emailShared.match(emailAddress)) {
      this.setState({
        emilShareError: true,
        validationsError:"The email address you entered is not valid.",
        copySuccessMesssage: ""
      });
    }else{
      let apiBody = {
        email: emailShared,
        link_share_id: linkShareId
    }
      this.postLinkShareEmailApiCallId = await this.apiCallData({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.linkShareEmailEndPoint,
        body: apiBody
      });
    }  
  }
  
   handleRevokeAccess = async () => {
    let { linkShareId } = this.state;
      let apiBody = {
        link_share_id: linkShareId
      };
      this.revokeAccessCopyApiCallId = await this.apiCallData({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.revokeAccessCopyEndPoint,
        body: apiBody
      });
  };

  copyToClipboard = () => {
     navigator.clipboard.writeText(this.state.initialLinkToCopy)
      this.setState({
        copySuccessMesssage: "Your have successfully copied the link",
        validationsError: "",
        emilShareError: false
      });
  };

  handleTooltip = () =>{
    this.setState({
      openMobileTooltip: !this.state.openMobileTooltip
    });
  }

  formatMonthAndDate = (date: Date | null) => {
    return date ? new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' }) : '';
  };

  handleDownloadZipFiles = async () => {
    this.downloadZipFileDataApiCallId = await this.apiCallData({
      contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: `${configJSON.downloadZipFileEndPoint}?user_survey_id=${this.state.downloadZipFileId}`
   
    })
    }
    handleZipFileLinkClick = () =>{
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = this.state.baseURL+"/bx_block_survey/user_survey_media/download_uploaded_files?user_survey_id="+this.state.downloadZipFileId;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(window.location.href);
    }
    
    getSavePdfDetails = async() => {
      this.getSaveReportPdfApiCallId = await this.apiCallData({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: `${configJSON.saveReportAsPdfEndPoint}${this.state.downloadZipFileId}`
      });
    };

  handleDownloadReportPDF = async () => {
    let pdfSurveyId = this.state.downloadZipFileId
    let absoluteUrl = `${configJSON.savePdfReportUrl}${pdfSurveyId}`;
    let openPdfUrl = `${window.location.origin}/${absoluteUrl}`;    
    window.open(openPdfUrl,"_blank");
  };

  handleDownloads = () => {
    this.setState({
      openDownloadPopup: false
    }, () => {
      this.handleDownloadReportPDF();
    });
  };
  handleFormateDate = (date:string) =>{
    return moment(date).format('MM/DD/YYYY') 
   }
  // Customizable Area End
}
