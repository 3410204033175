import { Typography, styled,Box } from "@material-ui/core";
import React from "react";

import Close from "@material-ui/icons/CloseRounded";
import "../assets/styles/index.css";
import { CustomFormLink as ICustomFormLink } from "./CustomFormController.web";
import  SectionSearchBar  from "./SectionSearchBar";
import {
  SidebarDrawer,
  LinksBorderWrapper,
  LinksWrapper,
} from "../../navigationmenu/src/SidebarNavigationMenu.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onLinkClick: (link: number) => void;
  links: ICustomFormLink[];
  searchSpecificSec:string;
  specificSecSearchesData:any;
  handleSectionSearchBar:(text:string)=>void;
  setOpenSidebar:any,
  sectionSubSectionList:any,
  navigationSearchSections:any,
  listOfSectionsSubsections:any,
  linksSectionsSubsections:any,
  subSectionName:string,
  openAccordions:boolean[],
  externalLink: boolean
}
interface S {}
interface SS {}
const CustomFormLink = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontWeight: 400,
  fonstSize: "18px",
  lineHeight: "27px",
  color: "#181818",
  "@media(max-width: 900px)": {
    color: "white",
  },
});

const CustomFormLinkSubSection = styled(Typography)({
color:"#181818",
fontFamily:"Poppins",
fontSize:"16px",
fontStyle:"normal",
fontWeight:400,
lineHeight:"normal",
paddingLeft:"8px",
paddingTop:"0px",
cursor:"pointer",
  "@media(max-width: 900px)": {
    color: "white",
  },
});

const CustomFormSidebarDrawer = styled(SidebarDrawer)({
  '& .MuiDrawer-paperAnchorLeft': {
    position: 'relative',
    overflow: 'visible',
    top: 0,
    left: 0,
    zIndex: 2
  },
  top: 195,
  "@media(max-width: 900px)": {
    margin: "0px 0px 0px 0px",
    top: 75,
    left: 0,
    position: "absolute",
    height: "calc(100vh - 75px)",
    width: "100%",

    border: "none",
    "@media(max-width: 435px)":{
    top: 50,
    }
  },

  "& .MuiPaper-root": {
    "@media(max-width: 900px)": {
      width: "100%",
      background: "linear-gradient(to right, #34B9FC, #7B61FD)",
      color: "white",
      borderRadius: "4px 20px 20px 4px",
    },
  },

  "& .LinksBorderWrapper": {
    "@media(max-width: 900px)": {
      backgroundColor: "transparent",
    },
  },
  "& .LinksBorderWrapper2": {
    background: 'unset',
    "@media(max-width: 900px)": {
      backgroundColor: "transparent",
    },
  },
});

const CloseIcon = styled(Close)({
  cursor: "pointer",
  display: "none",

  "@media(max-width: 900px)": {
    display: "block",
    margin: "10px 0 0 18px",
  },
});

const SearchSectionBox = styled(Box)({
  borderRadius: "0px 0px 8px 8px",
  background: "#EBF5FE",
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  paddingBottom:"20px",
  position:'absolute',
  width: '100%'
})

const SearchSectionBoxText = styled(Typography)({
color:"#000",
fontFamily:"Poppins",
fontStyle:"normal",
fontWeight:400,
lineHeight:"normal",
cursor:"pointer",
padding:"7px 0px 7px 23px",
})

export class CustomFormSidebar extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }
  render(): React.ReactNode {
    const { isOpen, handleClose, onLinkClick,searchSpecificSec,handleSectionSearchBar,specificSecSearchesData,navigationSearchSections,linksSectionsSubsections, externalLink} = this.props;
    const drawerMargin= ()=>{
      if (window.innerWidth<600) 
        return "0px" 
      if(externalLink) 
        return "75px 0px 0px 30px" 
      return "145px 0px 0px 30px " 
    }
    return (
      <CustomFormSidebarDrawer
        variant="persistent"
        anchor="left"
        onClose={handleClose}
        open={isOpen}
        ModalProps={{ keepMounted: true }}
        style={{ margin:drawerMargin() }}
      >
        <CloseIcon onClick={handleClose} />
        <LinksBorderWrapper padding={"1px 1px 1px 1px !important"}>
          <LinksWrapper className="LinksBorderWrapper">
            {linksSectionsSubsections.map((link:any) => (
              <React.Fragment key={`link_${link.id}`}>
              <CustomFormLink
                style={{ cursor: "pointer" }}
                className={link.isSelected ? "selectedLink" : ""}
                data-test-id="custom-form-link1"
                onClick={() => {
                  onLinkClick(link.id);
                  window.innerWidth < 901 && handleClose();
                }}
              >
                {link.survey_section_name}
              </CustomFormLink>
              {Array.isArray(link.user_survey_sub_sections) && link?.user_survey_sub_sections?.length>1 && link?.user_survey_sub_sections.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id).map((elm:any)=>{
                return <CustomFormLinkSubSection data-test-id="custom-form-link3" key={elm.id}  onClick={() => {
                  onLinkClick(elm.id);
                  window.innerWidth < 901 && handleClose();
                }}>
                 {elm.survey_sub_section_name}
              </CustomFormLinkSubSection>
              })}
              
              </React.Fragment>
            ))}
          </LinksWrapper>
        </LinksBorderWrapper>
        <LinksBorderWrapper marginTop={"20px"} className="LinksBorderWrapper2">
          <SectionSearchBar searchSpecificSec={searchSpecificSec} onLinkClick={onLinkClick} specificSecSearchesData={specificSecSearchesData} handleSectionSearchBar={handleSectionSearchBar}/>
          {Array.isArray(navigationSearchSections) && navigationSearchSections.length > 0 && searchSpecificSec ? <SearchSectionBox>
                {navigationSearchSections.map((section: any,idx:number) => {
                  return <React.Fragment key={idx}>
                  <SearchSectionBoxText onClick={()=>{
                    onLinkClick(section.id)}}
                    data-test-id="custom-form-link2"
                    >
                      {section.survey_section_name}
                      </SearchSectionBoxText>
                    {Array.isArray(section.user_survey_sub_sections) && section?.user_survey_sub_sections?.length > 0 && section.user_survey_sub_sections.map((elm:any,index:number)=>{
                      return <SearchSectionBoxText key={index} onClick={()=>{
                        onLinkClick(elm.id)}}
                        data-test-id="custom-form-link4"
                        >
                          {elm.survey_sub_section_name}
                          </SearchSectionBoxText>
                    })}
                  </React.Fragment>
                  })}
               
      </SearchSectionBox>:null}
        </LinksBorderWrapper>
      </CustomFormSidebarDrawer>
    );
  }
}
